import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { Link } from 'react-router-dom'
import { useRef } from "react";
import './home.css';

const Home = () => {
  const ref = useRef(null);

  const options = {
    smooth: true,
  } 
  return (
    <LocomotiveScrollProvider options={options} containerRef={ref}>
    <div className="App">
      <main data-scroll-container ref={ref}>
        <section className='intro' data-scroll data-scroll-speed="4"  data-scroll-section>
          <img width="250px" src="/logos/almargenlogo4.png" alt="logo"></img>
          <div>
            <h1>Almargen Estamos</h1>
            <p className='intro-p'>Escogemos el arte que nos rompe. No concebimos la propuesta estética sin todas las categorías del arte: lo cómico, lo trágico, lo grotesco, lo simple y lo digerible, incluyendo la belleza como posibilidad. Pero nos atrae y nos gusta más el arte que nos toca. Ir a una obra, oler un libro, ver una película, estar al frente de un lienzo nos cambia, nos transforma, no somos los mismos. Si el arte no nos inquieta, no lo consideramos arte.</p>
          </div>
        </section>
        <section className='contents1' data-scroll-section>
          <div className="thumbnail__div ">   
            <picture className="responsive-img">
                <img src="/logos/img1.jpeg" alt="img1" ></img>
            </picture>     
            
            <div className="thumbnail-info">
                <h1 href="#" className="thumbnail-info__link">Quiénes somos</h1>
                <p className='intro-p'>Somos un grupo de creativos y artistas que se ponen al servicio de las organizaciones con el fin de movilizar los valores culturales que propician una mejor sociedad.</p>
            </div>
          </div>

          <div className="thumbnail__div ">   
            <picture className="responsive-img">
                <img src="/logos/img2-2.jpg" alt="img1" ></img>
            </picture>     
            
            <div className="thumbnail-info">
                <h1 href="#" className="thumbnail-info__link">Líneas de trabajo</h1>
                <ul>
                  <li>Línea editorial y talleres</li> 
                  <li>Movilización ciudadana</li>
                  <li>Comunicación estratégica, física y digital</li>
                </ul>
            </div>
          </div>

          <div className="thumbnail__div ">   
            <picture className="responsive-img">
                <img src="/logos/img3.jpeg" alt="img1" ></img>
            </picture>     
            
            <div className="thumbnail-info">
                <h1 href="#" className="thumbnail-info__link">Creación de marcas y comunicaciones</h1>
                <p className='intro-p'>Impulsamos la participación activa de las comunidades en proyectos sociales, culturales y ambientales, diseñando campañas estratégicas que conectan los espacios físicos y digitales.</p>
            </div>
          </div>
        </section>
        <section className='contents2' data-scroll-section>
            <h1 href="#" className="thumbnail-info__link">Documentación</h1>
            <Link className='nav-link' to='/regimen-tributario-especial'>
                Régimen Tributario Especial
            </Link>
        </section>
      </main>
    </div>
    </LocomotiveScrollProvider>
  );
}

export default Home;
