import './rte.styles.scss'

const RTE = () => {
    return (
        <div className='container-rte'>
            <h1>Documentación</h1>

            <a href="/" >
            <i>Regresar a página principal ‹‹‹ </i>
            </a>
            <br/ >
            <br/ >
            <a href="/rte/Acta autorizacion representante legal para la solicitud de RTE.pdf" download>
                <i>Acta autorizacion representante legal para la solicitud de RTE ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificacion Antecedente judiciales.pdf" download>
                <i>Certificacion Antecedente judiciales ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificacion honorarios.pdf" download>
                <i>Certificacion honorarios ↗</i>
            </a>
            <br/ >
            <a href="/rte/certificado de existencia y representacion legal.pdf" download>
                <i>Certificado de existencia y representacion legal ↗</i>
            </a>
            <br/ >
            <a href="rte/Documento de Constitucion Fundacion Almargen.pdf" download>
                <i>Documento de Constitución Fundación Almargen ↗</i>
            </a>
            <br/ >
            <a href="rte/Estado de situacion financiera de apertura.pdf" download>
                <i>Estado de situación financiera de apertura ↗</i>
            </a>
            <br/ >
            <a href="rte/Estatutos FUNDACION ALMARGEN.pdf" download>
                <i>Estatutos FUNDACION ALMARGEN ↗</i>
            </a>
            <br/ >
        </div>
    );
}
    
export default RTE;
            