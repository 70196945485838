import { Routes, Route } from 'react-router-dom'
import Home from './routes/home/home.component'
import RTE from './routes/regimenTributarioEspecial/rte.component'
import './App.css';

function App() {
  return (
    <Routes>
      <Route path ='/' element={<Home />}></Route>
      <Route path ='/regimen-tributario-especial' element={<RTE />}></Route>
    </Routes>
  );
}

export default App;
